import axios from 'axios';
import common from './common';
import qs from 'qs'
/*
const http = axios.create({
    timeout: 15000
  });
  
  // request拦截器
  
  http.interceptors.request.use(function(config){
      config.data = config.data?config.data: {}
      // 把token添加到请求头,每次请求都带上
      config.headers = {
        //'Content-type': 'application/json',
        //'authorization': store.state.token==null?"":store.state.token
      }
      return config;
  })
  */

  export default class BaseService {
      static uploadImg = async (vm,formData) => {
        let config = {
            headers:{'Content-Type':'multipart/form-data'}
          }; 
        const response = await axios.post(common.resources.base.uploadImg,formData,config);
        return response;
      }

      static getGradeArticle = async (vm,obj) => {
        const response = await axios.post(common.resources.base.getGradeArticle,qs.stringify(obj));
        return response;
      }

      static upMyIdea = async (vm,obj) => {
        const response = await axios.post(common.resources.base.upMyIdea,qs.stringify(obj));
        return response;
      }
      static block = async (vm,obj) => {
        const response = await axios.post(common.resources.base.block,qs.stringify(obj));
        return response;
      }
      
      
  }